import { appVersion } from './app-version';
import { Environment } from './environment.model';

export const environment: Environment = {
    production: true,
    instana: false,
    env: 'production',
    version: appVersion,
    legalAppIdEndCustomer: 'f8fa1044-e87e-441b-be46-56ddadb4309f',
    legalAppIdCustomer: '9e583428-abbe-4bf3-8a36-855af8ec62a3',
    statusPageAppId: 'kzwx14m9nlv4',
    http: {
        registerBaseUrl: 'https://api.viessmann.com/backend/user-registration/v1/',
        userManagementBaseUrl: 'https://api.viessmann.com/backend/user-mgmt/v1/',
        companiesBaseUrl: 'https://api.viessmann.com/backend/user-mgmt/v1/',
        companyV2BaseUrl: 'https://api.viessmann.com/company/v2/',
        companyBaseUrl: 'https://api.viessmann.com/company/v3/',
        legalBaseUrl: 'https://api.viessmann.com/legal/v3/',
        usersBaseUrl: 'https://api.viessmann.com/users/v1/',
        usersV2BaseUrl: 'https://api.viessmann.com/users/v2/',
        translationsBaseUrl: 'https://api.viessmann.com/translations/v2/',
        statusPageBaseUrl: 'https://api.viessmann.com/status/v1/',
        viCareBaseUrl: 'https://api.viessmann.com/backend/vicare/v2/',
        purchasesBaseUrl: 'https://api.viessmann.com/purchases/v1/',
        monetizationBaseUrl: 'https://api.viessmann.com/monetization/v1/',
        notificationBaseUrl: 'https://api.viessmann.com/notifications/v1/',
        apiBaseUrl: 'https://api.viessmann.com/',
    },
    IAMConfig: {
        auth: {
            baseUrl: 'https://api.viessmann.com/',
            appId: 'customer-self-service',
        },
    },
    allowedPhoneCountryCodes: [
        'de',
        'fr',
        'gb',
        'ie',
        'at',
        'be',
        'lu',
        'ro',
        'it',
        'us',
        'ca',
        'ch',
        'es',
        'dk',
        'tr',
        'hu',
        'cz',
        'sk',
        'ru',
        'nl',
        'se',
        'fi',
        'bg',
        'si',
        'hr',
        'lv',
        'rs',
        'lt',
        'ua',
        'ee',
        'cn',
        'by',
        'kz',
        'ae',
        'hk',
    ],
    globalHeader: {
        baseUrl: 'https://api.viessmann.com',
        accountAppUrl: 'https://account.viessmann.com',
        partnerPortalBaseUrl: 'https://partnerportal.viessmann.com',
    },
    forbiddenCountries: {
        contacts: ['9000'],
        rolesAndPermissions: ['9000'],
        notifications: [],
    },
    allowedServiceCaseCountryCodes: ['DE', 'AT', 'BE', 'CH'],
    reCaptchaSiteKey: '6LckTlgkAAAAAKXSuj5yvsACvyAaw5BpmFg0k4KO',
    baseUrl: 'https://account.viessmann.com',
    ekCountriesWithBankAccountManagement: [
        'DE',
        'CH',
        'AT',
        'FR',
        'IT',
        'NL',
        'BE',
        'PL',
        'CZ',
        'LU',
        'GB',
        'UK',
        'RS',
        'LT',
        'LV',
        'TR',
        'CA',
    ],
    allowedNotificationSalesOrgs: ['0500', '6000'],
    allowedPersonalNotificationSalesOrgs: [],
    viessmannHome: 'https://home.viessmann.com/de/de/dashboard.html',
    forbiddenLiteCountryCodes: ['SK'],
};
