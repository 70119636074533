import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpEventType,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
    allowedUrls = [environment.http.apiBaseUrl];

    constructor(private auth: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<HttpEventType.Response>> {
        // skip intercept for assets
        if (request.url.includes('assets/')) {
            return next.handle(request);
        }

        // skip intercept if we do not call a Viessmann API. Should be never the case but just to be sure...
        if (!this.isAllowedUrl(request.url)) {
            return next.handle(request);
        }

        // skip intercept for status page and translations request
        if (this.skipIntercept(request.url)) {
            return next.handle(request);
        }

        // Don't intercept for following paths
        const paths = [
            '/register-end-customer',
            '/forgot-username',
            '/register-customer',
            '/register',
            '/setup/login-or-register',
        ];

        if (paths.filter((path) => location.href.includes(path)).length > 0) {
            return next.handle(request);
        }

        return this.auth.csrfToken$.pipe(
            first((val) => {
                return val !== undefined;
            }),
            switchMap((value) => {
                return next.handle(request.clone({ headers: request.headers.set('Authorization', `CSRF ${value}`) }));
            }),
            catchError((e) => {
                if (e instanceof HttpErrorResponse && e.status === 401) {
                    // refresh CSRF token and retry active request if fetching of token succeed
                    return this.auth.fetchNewCsrfToken().pipe(switchMap(() => this.intercept(request, next)));
                }
                throw e;
            }),
        );
    }

    private isAllowedUrl(url: string): boolean {
        const found = this.allowedUrls.find((u) => url.startsWith(u));
        return !!found;
    }

    private skipIntercept(url: string): boolean {
        return url.includes(environment.http.translationsBaseUrl) || url.includes(environment.http.statusPageBaseUrl);
    }
}
